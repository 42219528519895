.menuBar{
    /*background-color: rgb(64, 64, 104);*/
    background-color:ghostwhite;
    width: 230px;
    height: auto;
    margin: 0;
    padding: 10px 0px;
    color: rgb(61, 61, 61);
    transition: width 0.3s ease;
}

.Collapsed-menuBar{
    background-color: ghostwhite;
    width: 100px;
    height: 100%;
    margin: 0;
    padding: 10px 0px;
    color: rgb(61, 61, 61);
    transition: width 0.3s ease;
}

.menuBar .container, .Collapsed-menuBar .container{
    cursor: pointer;
    height: 50px;
    width: 6px;
    margin: 20px 0px;
    margin-left: 20px;
    align-items: center;
    padding: 0;
    padding-left: 5px;
    display: block flex;
    font-weight: bold;
}

.menuBar .container.topic, .Collapsed-menuBar .container.topic{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: none;
    padding: 0;
}

.menuBar .container.topic:hover, .Collapsed-menuBar .container.topic:hover{
    background-color:transparent;
    color: rgb(30, 29, 29);
}

.menuBar .container.topic span{
    font-size: 25px;    
}

.menuBar .container.topic .icon, .Collapsed-menuBar .container.topic .icon{
    font-size: 40px;
    padding: 0;
}

.menuBar .container.active, .Collapsed-menuBar .container.active{
    background-color: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    border-radius: 25px;
}

.menuBar .container:hover, .Collapsed-menuBar .container:hover{
    background-color: rgba(78, 78, 78, 0.589);
    border-radius: 25px;
}

.menuBar .container .icon, .Collapsed-menuBar .container .icon{
    font-size: 25px;
    margin: 0 20px 0 10px;
}

.menuBar .container span{
    font-size: 20px;
}