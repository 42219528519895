.body{
    background-color: rgba(0, 0, 0, 0.322);
    width: 100%;
}

.box{
    background-color: black;
    width: 100%;
    height: 70px;
}

