.Card-div{
    width: 80%;
    height: 580px;
    max-width: 1200px;
    min-width: 380px;
    min-height: 580px;
    border: 3px solid #666666;
    border-radius: 10px;
    margin: 0;
    padding: 10px;
    padding-bottom: 40px;
    backdrop-filter: blur(40px);
    background-color: transparent;
}

.Card-div h1{
    font-family: ROBOTO;
    font-size: 48px;
    color: white;
    text-align: center;
    margin: 30px 0 20px 0;
}

.CardHolder{
    width: 100%;
    height: 420px;
    margin: 0;
    padding: 0;
    justify-content: space-evenly;
    max-height: 100%;
    overflow: auto;
    background-color: transparent;  
}