.OTP-Wrappper{
    width: 400px;
    height: auto;
    min-height: 200px;
    min-width: 400px;
    border-radius: 10px;
    border: 3px solid #666666;
    margin: 0;
    padding: 10px 20px;
    background-color: transparent;
    backdrop-filter: blur(40px);
    color: white;
}

.OTP-Wrappper h3{
    font-size: 20px;
    text-align: center;
    margin: 10px 0 15px 0;
}

.OTP-Wrappper label{
    font-size: 14.7px;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
}

.otp-area{
    justify-content: center;
    display: flex;
}

/*rgba(255, 255, 255, 0.795)*/

.btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.btn-container Button{
    font-family: ROBOTO;
    font-size: 18px;
    margin: 20px 40px;
    width: 100px !important;
    height: 40px !important;
    padding: auto;
}

